import styles from "../styles/Loader.module.scss";

const Loader = ({ color }) => {
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.loadingCandidates}></div>
      <p className={`${styles.loadingText} ${color}`}>Loading...</p>
    </div>
  );
};

export default Loader;
