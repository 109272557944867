import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import styles from "../styles/GlobalMenu.module.scss";
import downArrow from "../../public/images/icons/angle-down.png";
import GlobalSearch from './GlobalSearch';

const showMenu = React.createRef();
const clickMenu = () => {
  showMenu.current.classList.toggle(styles.show);
};
const GlobalMenu = ({ candidateScope, wrapper = styles.wrapper }) => {
  let router = useRouter();
  let pathname = router.pathname;

  const loader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };
  const ifPathIs = (arr, search) => {
    return Boolean(arr.find((res) => res.includes(search)));
  };

  return (
    <div className={`${styles.bannerMenu} ${wrapper}`}>
      <ul>
        <li
          className={`${styles.btnMenu} ${styles.active}`}
          onClick={clickMenu}
        >
          <button>
            {pathname === "/" ? (
              <p className={styles.menuTitle}>Election Homepage </p>
            ) : pathname.includes("/tale-of-the-tape") ? (
              <p className={styles.menuTitle}>Tale of the Tape</p>
            ) : pathname.includes("/races") ||
              (pathname.includes("/candidates") &&
                candidateScope === "NATIONAL") ? (
              <p className={styles.menuTitle}>National races</p>
            ) : pathname.includes("/2022/[...local_place]") ||
              pathname.includes("/2022/metro-manila") ||
              pathname.includes("/2022/provinces") ||
              pathname.includes("/2022/independent-cities") ||
              (pathname.includes("/candidates") &&
                candidateScope === "LOCAL") ? (
              <p className={styles.menuTitle}>Local races</p>
            ) : pathname.includes("videos-shows-podcasts-multimedia") ? (
              <p className={styles.menuTitle}>Multimedia</p>
            ) : (
              <p className={styles.menuTitle}>Election Homepage </p>
            )}
            <div className={styles.icon}>
              <Image
                loader={loader}
                src={downArrow}
                alt="arrow down"
                width={15}
                height={8}
              />
            </div>
          </button>
        </li>
      </ul>
      <ul className={styles.listMenu} ref={showMenu}>
        <li className={pathname === "/" ? styles.active : ""}>
          <Link href="/">
            <a>
              <p className={styles.menuTitle}>Election homepage</p>
            </a>
          </Link>
        </li>
        <li
          className={
            (pathname.includes("/races/president-vice-president") &&
              !pathname.includes(
                "/races/president-vice-president/tale-of-the-tape"
              )) ||
            pathname.includes("/races/senator") ||
            pathname.includes("/races/party-list") ||
            pathname.includes("/races/map") ||
            (pathname.includes("/candidates") && candidateScope === "NATIONAL")
              ? styles.active
              : ""
          }
        >
          <Link href="/elections/2022/races/president-vice-president/results">
            <a>
              <p className={styles.menuTitle}>National races</p>
            </a>
          </Link>
        </li>
        <li
          className={
            pathname.includes("/2022/[...local_place]") ||
            pathname.includes("/2022/metro-manila") ||
            pathname.includes("/2022/provinces") ||
            pathname.includes("/2022/independent-cities") ||
            (pathname.includes("/candidates") && candidateScope === "LOCAL")
              ? styles.active
              : ""
          }
        >
          <Link href="/elections/2022/metro-manila">
            <a>
              <p className={styles.menuTitle}>Local races</p>
            </a>
          </Link>
        </li>
        <li
          className={
            pathname.includes(
              "/races/president-vice-president/tale-of-the-tape"
            )
              ? styles.active
              : ""
          }
        >
          <Link href="/elections/2022/races/president-vice-president/tale-of-the-tape?selectedCandidates=&race=presidential">
            <a>
              <p className={styles.menuTitle}>Tale of the Tape</p>
            </a>
          </Link>
        </li>
        <li>
          <Link href="https://www.rappler.com/nation/elections/survey-monitor-polls-philippines-2022/">
            <a target="_blank">
              <p className={styles.menuTitle}>Survey Monitor</p>
            </a>
          </Link>
        </li>
        <li>
          <Link href="https://www.rappler.com/nation/elections/comprehensive-guide-philippines-2022-polls/">
            <a target="_blank">
              <p className={styles.menuTitle}>Voting Guides</p>
            </a>
          </Link>
        </li>
        <li>
          <Link href="https://www.rappler.com/nation/elections/schedule-campaign-activities-sorties-presidential-vp-senatorial-candidates-2022-polls-philippines/">
            <a target="_blank">
              <p className={styles.menuTitle}>Campaign schedule</p>
            </a>
          </Link>
        </li>
        <li
          className={
            pathname.includes("videos-shows-podcasts-multimedia")
              ? styles.active
              : ""
          }
        >
          <Link href="/elections/2022/videos-shows-podcasts-multimedia">
            <a>
              <p className={styles.menuTitle}>Multimedia</p>
            </a>
          </Link>
        </li>
      </ul>
      <GlobalSearch />
    </div>
  );
};

export default GlobalMenu;
