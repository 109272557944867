const electionCountDown = () => {
  let daysLeft = {};
  let electionDate = new Date("05/09/2022 12:00:00 AM UTC");

  const difference =
    +new Date(
      electionDate.getTime() + electionDate.getTimezoneOffset() * 60 * 1000
    ) - +new Date();

  if (difference > 0) {
    daysLeft = {
      days: Math.ceil(difference / (1000 * 60 * 60 * 24)),
    };
  }
  return daysLeft;
};

const isElectionDayToday = () => {
  const electionDate = new Date("2022-05-09");
  const today = new Date();

  return today >= electionDate;
};

export { electionCountDown, isElectionDayToday };
