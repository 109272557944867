import Link from "next/link";
import Image from "next/image";
import React, { useState, useEffect, useRef } from "react";

import useDebounce from '../hooks/useDebounce';

import SearchIcon from "../../public/images/icons/search.svg";
import CloseIcon from "../../public/images/icons/close.svg";
import SpinnerIcon from "../../public/images/icons/spinner.svg";
import styles from "../styles/GlobalMenu.module.scss";

const BASE_URL = `${(process.env.SITE_URL || 'https://ph.rappler.com/')}elections/2022`;
function getURL(item) {
  if (item.id === "president" || item.id === "vice-president") {
    return `${BASE_URL}/races/president-vice-president/results`;
  }
  if (item.id === "senator") {
    return `${BASE_URL}/races/senator/results`;
  }
  if (item.type === "pl") {
    return `${BASE_URL}/races/party-list/results`;
  }

  //special case for taguig because life is weird
  switch (item.url) {
    case 'ncr-taguig-city-lone-legislative-district':
      return `${BASE_URL}/ncr-taguig-city`;
    case 'ncr-taguig-pateros-lone-legislative-district':
      return `${BASE_URL}/ncr-pateros`;
    default:
      return `${BASE_URL}/${item.url}`;
  }
}

const RANK_BOOST_FACTOR = 0.8;

const typeMap = {
  "lc": {
    name: "Candidate",
    rank: 1
  },
  "nc": {
    name: "Candidate",
    rank: 0
  },
  "o": {
    name: "Position",
    rank: 4
  },
  "pl": {
    name: "Party List",
    rank: 3
  },
  "c": {
    name: "Place",
    rank: 2
  },
  "p": {
    name: "Place",
    rank: 1
  }
};


function computeSubrank(item) {
  let subrank = 0;
  switch (item.id) {
    case 'president':
      subrank = 0;
      break;
    case 'vice-president':
      subrank = 1;
      break;
    case 'senator':
      subrank = 3;
      break;
    default:
      switch (item?.contest?.display_name) {
        case 'President':
          subrank = 0;
          break;
        case 'Vice President':
          subrank = 1;
          break;
        case 'Senator':
          subrank = 2;
          break;
        default:
          subrank = 3;
          break;
      }
  }

  return subrank * 0.25;
}
export default function GlobalSearch() {
  const [searchResults, setSearchResults] = useState(null);
  const [queryName, setQueryName] = useState("");
  const [isSearchBoxFocused, setIsSearchBoxFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedQuery = useDebounce(queryName, 300);

  const loader = ({ src, width, quality }) => {
    const imageUrl = process.env.SITE_URL;
    return `${imageUrl}${src}?w=${width}&q=${quality || 75}`;
  };

  const search = useRef();
  const searchRef = useRef();
  const searchResultsRef = useRef();

  const onFocus = () => setIsSearchBoxFocused(true);

  const onBlur = () => setIsSearchBoxFocused(false);

  const handleKeyboardNav = e => {
    if (e.key === 'Enter') {
      // makes sure to click link if search result is the current page
      const clickSearchItem = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      e.target.dispatchEvent(clickSearchItem);

      // set spinner and collapse
      setIsLoading(true);
      setIsSearchBoxFocused(false);
    }
  };

  useEffect(() => {
    search.current = new Worker(new URL('../search-worker.js', import.meta.url));
    setIsLoading(true);
    search.current.onmessage = (event) => {
      const results = event.data.result;
      results.sort((lhs, rhs) => {
        const lboost = typeMap[lhs.type]?.rank + computeSubrank(lhs);
        const rboost = typeMap[rhs.type]?.rank + computeSubrank(rhs);

        return (rhs.score - RANK_BOOST_FACTOR * rboost) - (lhs.score - RANK_BOOST_FACTOR* lboost);
      });

      if (!results.length && !event.data.context.query) {
        setSearchResults(null);
      } else {
        setSearchResults(results);
      }
      setIsLoading(false);
    }
    return () => search.current.terminate();
  }, []);

  useEffect(() => {
    search.current.postMessage({ query: debouncedQuery });
  }, [search, debouncedQuery]);

  useEffect(() => {
    const handleClickOutside = e => {
      const searchResultsContainer = document.getElementById('searchResults');
      const searchBoxContainer = document.getElementById('searchBox');
      const noResultsSpan = document.getElementById('noResults');
      const clickSearchItem = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      if (
        !searchBoxContainer?.contains(e.target) &&
        e.target.nodeName !== 'LI' &&
        e.target.nodeName !== 'A' &&
        noResultsSpan !== e.target
      ) {
        if (searchResultsContainer?.contains(e.target)) {
          e.target.dispatchEvent(clickSearchItem);
          setIsLoading(true);
        }
        setIsSearchBoxFocused(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchResults || !debouncedQuery) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [searchResults, debouncedQuery]);

  return (
    <div className={styles.container} onFocus={onFocus}>
      <div className={styles.search}>
        <input
          type="text"
          id="searchBox"
          value={queryName}
          placeholder="Search Candidates, Positions, and Places"
          className={styles.searchBox}
          onChange={(event) => setQueryName(event.target.value)}
          onFocus={onFocus}
          ref={searchRef}
        />
        <div className={styles.searchIcon}>
          <Image
            loader={loader}
            src={isLoading ? SpinnerIcon : (isSearchBoxFocused ? CloseIcon : SearchIcon)}
            onClick={onBlur}
            alt="search"
            layout="responsive"
            width={17.49}
            height={17.49}
          />
        </div>
        {
          searchResults ? (
            searchResults && searchResults.length ? (
              <ol id="searchResults" className={`${styles.searchResults} ${!isSearchBoxFocused && styles.removeSearchResults}`} ref={searchResultsRef}>
                {searchResults.map(item =>
                  <li key={item.id}>
                    <Link href={getURL(item)}>
                      <a onKeyDown={handleKeyboardNav}>
                        <div className={styles.searchItem}>
                          <div>
                            <div><span className={styles.title}>{item.name}</span></div>
                            <span>{item.desc}</span>
                          </div>
                          <div>
                            <span className={styles.type}>{typeMap[item.type]?.name ?? ''}</span>
                          </div>
                        </div>
                      </a>
                    </Link>
                  </li>
                )}
              </ol>
            ) :
              <ol className={`${styles.searchResults} ${!isSearchBoxFocused && styles.removeSearchResults}`} ref={searchResultsRef}>
                <li>
                  <span id="noResults" className={styles.type}>No results found.</span>
                </li>
              </ol>
          ) : null
        }
      </div>
    </div>
  );
}
