import React from "react";
import Link from "next/link";
import Image from "next/image";
import styles from "../styles/Header.module.scss";

const overlay = React.createRef();
const subMenuSections = React.createRef();
const subMenuTopics = React.createRef();
const removePanel = React.createRef();
const showBackBtn = React.createRef();

const openNavigation = () => {
  overlay.current.classList.add(styles.burgerNavOverlay__show);
};

const openTopics = () => {
  removePanel.current.classList.remove(styles.burgerNav__main_panel__show);
  subMenuSections.current.classList.remove(styles.burgerNav__sub_panel__show);
  subMenuTopics.current.classList.add(styles.burgerNav__sub_panel__show);
  showBackBtn.current.classList.add(styles.burgerNav__top_bar__show_back);
};

const openSections = () => {
  removePanel.current.classList.remove(styles.burgerNav__main_panel__show);
  subMenuTopics.current.classList.remove(styles.burgerNav__sub_panel__show);
  subMenuSections.current.classList.add(styles.burgerNav__sub_panel__show);
  showBackBtn.current.classList.add(styles.burgerNav__top_bar__show_back);
};

const closeSubMenu = () => {
  removePanel.current.classList.add(styles.burgerNav__main_panel__show);
  subMenuSections.current.classList.remove(styles.burgerNav__sub_panel__show);
  subMenuTopics.current.classList.remove(styles.burgerNav__sub_panel__show);
  showBackBtn.current.classList.remove(styles.burgerNav__top_bar__show_back);
};

const closeNavigation = () => {
  removePanel.current.classList.add(styles.burgerNav__main_panel__show);
  overlay.current.classList.remove(styles.burgerNavOverlay__show);
  subMenuSections.current.classList.remove(styles.burgerNav__sub_panel__show);
  subMenuTopics.current.classList.remove(styles.burgerNav__sub_panel__show);
};

const Header = () => {
  return (
    <header className={styles.siteHeader}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerNav}>
          <button
            aria-label="Burger Nav"
            className={styles.navBurger}
            onClick={openNavigation}
          >
            <span className={styles.burgerContainer}>
              <span className={styles.burgerIcon} />
              <span className={styles.burgerIcon} />
            </span>
          </button>

          <div className={styles.burgerNavOverlay} ref={overlay}>
            <div className={styles.burgerNav}>
              <div className={styles.burgerNav__top_bar} ref={showBackBtn}>
                <div className={styles.burgerNav__top_bar_left}>
                  <button className={styles.burgerNav__logo}>
                    <Image
                      alt="Rappler"
                      src="https://www.rappler.com/content/themes/rappler/src/images/logo.svg"
                      width={40}
                      height={40}
                    />
                  </button>

                  <button
                    className={`${styles.burgerNav__top_bar_button} ${styles.burgerNav__top_bar_button__back}`}
                    onClick={closeSubMenu}
                    aria-label="BurgerNav Top Bar"
                  >
                    <svg
                      viewBox="0 0 31 31"
                      className={styles.burgerNav__top_bar_back_button}
                    >
                      <g
                        id="Navigation"
                        stroke="none"
                        strokeWidth="1"
                        fill="#F04F3C"
                        fillRule="evenodd"
                      >
                        <g
                          id="M/Sections/L1"
                          transform="translate(-32.000000, -20.000000)"
                        >
                          <g
                            id="UI/Arrow/Orange"
                            transform="translate(32.000000, 20.000000)"
                            fill="inherit"
                          >
                            <path
                              d="M3.18350997,14.6880517 L30.3717613,14.6880517 L30.3717613,16.3722623 L3.27044743,16.3722623 L16.744404,29.8684666 L15.5525053,31.0584005 L6.1284311e-13,15.4802153 L15.5544218,-4.79616347e-14 L16.7424875,1.19376085 L3.18350997,14.6880517 Z"
                              id="Combined-Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>

                <div className={styles.burgerNav__top_bar_right}>
                  <button
                    className={styles.burgerNav__top_bar_button}
                    onClick={closeNavigation}
                    aria-label="BurgerNav Top Bar"
                  >
                    <svg width="32px" height="32px" viewBox="0 0 32 32">
                      <g
                        id="Navigation"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="M/Sections/L1"
                          transform="translate(-704.000000, -20.000000)"
                        >
                          <g
                            id="UI/Close/Orange"
                            transform="translate(704.000000, 20.000000)"
                            fill="#F04F3C"
                          >
                            <g id="Group">
                              <path
                                d="M16,14.8090833 L30.2910002,0.518083107 L31.4819169,1.70899979 L17.1909167,16 L31.4819169,30.2910002 L30.2910002,31.4819169 L16,17.1909167 L1.70899979,31.4819169 L0.518083107,30.2910002 L14.8090833,16 L0.518083107,1.70899979 L1.70899979,0.518083107 L16,14.8090833 Z"
                                id="Combined-Shape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              <div
                className={`${styles.burgerNav__panel}
                  ${styles.burgerNav__main_panel}
                  ${styles.burgerNav__panel_depth_0}
                  ${styles.burgerNav__main_panel__show}`}
                ref={removePanel}
              >
                <div className={styles.burgerNav__scrollable}>
                  <ul className="menu">
                    <li className="menu_item">
                      <a href="https://www.rappler.com/latest/">Latest news</a>
                    </li>
                    <li
                      className={`${styles.menu_item_has_children}
                        ${styles.menu_item}`}
                    >
                      <a onClick={openSections} href="#">
                        SECTIONS
                      </a>
                    </li>
                    <li
                      className={`${styles.menu_item_has_children}
                        ${styles.menu_item}`}
                    >
                      <a onClick={openTopics} href="#">
                        TOPICS
                      </a>
                    </li>
                    <li className="menu_item">
                      <a href="https://www.rappler.com/topic/special-coverages/">
                        SPECIAL COVERAGE
                      </a>
                    </li>
                    <li className="menu_item">
                      <a href="https://coupons.rappler.com/">COUPONS</a>
                    </li>
                    <li className="menu_item">
                      <a href="https://promocodes.rappler.com/">PROMO CODES</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className={`${styles.burgerNav__panel} 
                  ${styles.burgerNav__sub_panel}
                  ${styles.burgerNav__panel_depth_1}`}
                ref={subMenuTopics}
              >
                <div className={styles.burgerNav__scrollable}>
                  <ul className="sub-menu">
                    <li className={styles.menu_item_custom}>Trending</li>
                    <li>
                      <a href="https://www.rappler.com/topic/covid-19/">
                        COVID-19
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/nba-regular-season/">
                        NBA regular season
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/united-states/">
                        United States
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/tennis/">Tennis</a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/kazakhstan/">
                        Kazakhstan
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/covid-19-omicron-variant/">
                        Omicron variant
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/2022-philippine-elections/">
                        2022 Philippine Elections
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/covid-19-vaccines/">
                        COVID-19 vaccines
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/hollywood-movies/">
                        Hollywood movies
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/brandrap/">
                        #BrandRap
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/ej-obiena/">
                        EJ Obiena
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/novak-djokovic/">
                        Novak Djokovic
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/presidential-race-philippines/">
                        2022 PH presidential race
                      </a>
                    </li>
                    <li>
                      <a href="https://www.rappler.com/topic/commission-on-elections/">
                        Comelec
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className={`${styles.burgerNav__panel} 
                  ${styles.burgerNav__sub_panel}
                  ${styles.burgerNav__panel_depth_1}`}
                ref={subMenuSections}
              >
                <div className={styles.burgerNav__scrollable}>
                  <ul className="sub-menu">
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/plus-membership-program/">
                        Rappler+ Exclusives
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/about/">
                        About Rappler
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/brandrap/">
                        BrandRap
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/bulletin-board/">
                        Bulletin Board
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/business/">
                        Business
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/entertainment/">
                        Entertainment
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/environment/">
                        Environment
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/hustle/">
                        Hustle
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/life-and-style/">
                        Life and Style
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/moveph/">
                        MovePH
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/nation/">
                        Philippines
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/newsbreak/">
                        Newsbreak
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/science/">
                        Science
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/sports/">
                        Sports
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/technology/">
                        Technology
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/video/">Video</a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/voices/">
                        Voices
                      </a>
                    </li>
                    <li className={styles.menu_item_has_children}>
                      <a href="https://www.rappler.com/section/world/">World</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.headerLogo}>
          <Link href="https://www.rappler.com" rel="home" passHref>
            <span className={styles.logo} />
          </Link>
        </div>

        <div className={styles.headerSecondary}>
          <ul>
            <li>
              <a href="https://www.rappler.com/?s">
                <span className="icon icon--search"></span> Search{" "}
              </a>
            </li>
            <li>
              <a className={`${styles.joinBtn} piano_rappler-plus`} href="#">
                Join Rappler+{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
