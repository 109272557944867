import Link from "next/link";
import Image from "next/image";
import useSWRImmutable from "swr/immutable";
import { useEffect, useState } from "react";
import logo from "../../public/images/ATIN-PILIPINAS.png";
import { ProgressBarDefault } from "./ProgressBar";
import {
  electionCountDown,
  isElectionDayToday,
} from "../helpers/electionCountdown";
// import { ConvertDateToTimeSince } from "../helpers/util";
import styles from "../styles/Banner.module.scss";

const cfUrl = process.env.API_CF_URL;
const getRegionList = async () => {
  try {
    const res = await fetch(`${process.env.API_HOST}/api/regions`, {
      method: "GET",
      headers: {
        Authorization: process.env.API_TOKEN,
        "x-bypass-ratelimit": "pzQ!5eP7CWnUQSc</ojHpsd/gqfUJKgfKw=dH93po?fK",
      },
    });
    return await res.json();
  } catch (err) {
    return null;
  }
};

const Banner = () => {
  const [daysLeft, setDaysLeft] = useState({});
  const [latestResultsPHData, setLatestResultsPHData] = useState({});
  const [PHTotalPrecincts, setPHTotalPrecincts] = useState(1);

  const fetcher = (url, body) =>
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then(async (res) => {
      return res.json();
    });

  const { data: rawLatestResultsData, error: latestResultsPHError } =
    useSWRImmutable(
      [
        `${cfUrl}/latestTransmissionCount`,
        JSON.stringify({
          data: {
            locationSlug: "philippines",
          },
        }),
      ],
      fetcher,
      {
        refreshInterval: 0,
        shouldRetryOnError: true,
        revalidateOnReconnect: true,
        refreshWhenOffline: true,
        errorRetryInterval: 5000,
        errorRetryCount: 1,
      }
    );

  useEffect(() => {
    if (rawLatestResultsData && rawLatestResultsData.result) {
      setLatestResultsPHData(rawLatestResultsData.result);
    }
    const countdown = electionCountDown();
    setDaysLeft(countdown);

    const getPartyLists = async () => {
      const { results } = (await getRegionList()) || {};
      if (results) {
        setPHTotalPrecincts(
          results
            .map((region) => (region || {}).precincts_count)
            .reduce((a, b) => a + b, 0)
        );
      }
    };

    getPartyLists();
  }, [rawLatestResultsData]);

  let totalDaysLeft = Object.keys(daysLeft).map((res) => {
    if (!daysLeft[res]) return;

    return (
      <>
        {daysLeft[res] === 1 ? "ONE DAY " : `${daysLeft[res]} ${res} `}
        to go
      </>
    );
  });

  const loader = ({ src, width, quality }) => {
    const imageUrl = process.env.SITE_URL;
    return `${imageUrl}${src}?w=${width}&q=${quality || 75}`;
  };

  const getTransmissionPercentage = () => {
    if (latestResultsPHData && latestResultsPHData.transmissionCount) {
      return (
        (latestResultsPHData.transmissionCount / PHTotalPrecincts) *
        100
      ).toFixed(2);
    } else {
      return 0;
    }
  };

  const getTransmissionDate = () => {
    let timestampDate = new Date();
    if (latestResultsPHData && latestResultsPHData.timestamp) {
      timestampDate = new Date(latestResultsPHData.timestamp);
    }
    const hours = timestampDate.getHours();
    const minutes = timestampDate.getMinutes();
    const suffix =
      hours === 12 && minutes === 0
        ? "NN"
        : hours === 0 && minutes === 0
        ? "MN"
        : hours < 12
        ? "AM"
        : "PM";

    return `${timestampDate.toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })} ${hours % 12 === 0 ? 12 : hours % 12}${
      minutes > 0 ? (minutes > 9 ? `:${minutes}` : `:0${minutes}`) : ""
    }${suffix}`;
  };

  const RenderResultsBanner = () => {
    return (
      <div className={styles.resultsBanner}>
        {/* <div className={styles.resultsGrid}>
          <h4 className={styles.resultsHeading}>
            Total Election Results Processed
          </h4>
          <p className={styles.pubDate}>
            As of {ConvertDateToTimeSince(data.timestamp)}
          </p>
          <h3 className={styles.total}>{data.transmissionPercentage}%</h3>
          <ProgressBarDefault percentage={data.transmissionPercentage} />
        </div>
         <div className={styles.resultsGrid}>
          <h4 className={styles.resultsHeading}>Votes in</h4>
          <p className={styles.pubDate}>
            As of {ConvertDateToTimeSince(data.timestamp)}
          </p>
          <h3 className={styles.total}>{data.voteCount}</h3>
          <p className={styles.totalRegistered}>of the 65,745,529 registered</p>
        </div> */}

        <h4 className={styles.resultsHeading}>
          Total Election Results Processed
        </h4>
        <p className={styles.pubDate}>As of {getTransmissionDate()}</p>
        <h3 className={styles.total}>
          {PHTotalPrecincts === 1 ? "" : `${getTransmissionPercentage()}%`}
        </h3>
        <ProgressBarDefault
          percentage={
            PHTotalPrecincts === 1 ? undefined : getTransmissionPercentage()
          }
        />
      </div>
    );
  };

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerWrapper}>
        <div className={styles.bannerContent}>
          <div className={styles.logoWrapper}>
            <Link href="/" passHref>
              <a>
                <Image
                  loader={loader}
                  src={logo}
                  alt="We Decide"
                  layout="responsive"
                  // width={250}
                  // height={150}
                />
              </a>
            </Link>
          </div>
        </div>
        <div className={styles.bannerContent}>
          {/* {isElectionDayToday() ? (
            <>
              <h2 className={styles.bannerTitle}>2022 PHILIPPINE ELECTIONS</h2>
              <p className={styles.desc}>Election day</p>
            </>
          ) : (
            <>
              <h2 className={styles.bannerTitle}>2022 PHILIPPINE ELECTIONS</h2>
              <p className={styles.desc}>{totalDaysLeft}</p>
            </>
          )} */}

          {RenderResultsBanner()}
        </div>
      </div>
    </div>
  );
};

export default Banner;
