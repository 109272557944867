import Link from "next/link";
import {
  faFacebookSquare,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../styles/Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footerLayout}>
      <div className={styles.site_container}>
        <div className={styles.logoContainer}>
          <Link href="https://www.rappler.com/" rel="home" passHref>
            <span className={styles.logo} />
          </Link>
        </div>
        <div className={styles.newsletter}>
          <p
            className={styles.newsletterSubtitle}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.rappler.com/about-plus-membership-program"
          >
            JOIN OUR NEWSLETTER
          </p>
          <p className={styles.newsletterTitle}>
            Fearless reporting delivered to you
          </p>
          <div
            className={`site-footer__newsletter-subscribe ${styles.newsletterSubscribe}`}
          ></div>
          <div className={styles.social}>
            <p className={styles.socialTitle}>follow us</p>
            <div className={styles.socialIcons}>
              <Link href="https://facebook.com/rapplerdotcom/" passHref>
                <a
                  className={styles.socialLogo}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FontAwesomeIcon
                    className={styles.fbAndTwIcon}
                    icon={faFacebookSquare}
                  />
                </a>
              </Link>
              <Link href="https://twitter.com/rapplerdotcom/" passHref>
                <a
                  className={styles.socialLogo}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter"
                >
                  <FontAwesomeIcon
                    className={styles.fbAndTwIcon}
                    icon={faTwitter}
                  />
                </a>
              </Link>
              <Link href="https://www.youtube.com/channel/UCdnZdQxYXnbN4uWJg96oGxw">
                <a
                  className={styles.socialLogo}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Youtube"
                >
                  <FontAwesomeIcon className={styles.ytIcon} icon={faYoutube} />
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.menu}>
          <div className={styles.menu_promo}>
            <Link href="https://www.rappler.com/about-plus-membership-program">
              <a
                className={styles.menu_promo_item}
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Rappler+
              </a>
            </Link>
            <Link href="https://www.rappler.com/about-plus-membership-program">
              <a
                className={styles.menu_promo_subtitle}
                target="_blank"
                rel="noopener noreferrer"
              >
                Know more here
              </a>
            </Link>
          </div>
          <ul className={styles.menu_primary}>
            <li>
              <Link href="http://r3.rappler.com/crowdfunding">
                Crowdfunding
              </Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/about/the-people-behind-rappler">
                Founding board
              </Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/board-of-directors">
                Rappler board of directors
              </Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/section/about">About Us</Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/about/mission-statement-journalism-communities-technology">
                Mission Statement
              </Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/about/standards-guidelines-corrections-fact-check-content-comment-moderation">
                Editorial standards and guidelines
              </Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/about-rappler/about-us/21743-rappler-team">
                The Team
              </Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/about-rappler/about-us/1557-contact-us">
                Contact Us
              </Link>
            </li>
          </ul>
          <ul className={styles.menu_secondary}>
            <li>
              <Link href="https://www.rappler.com/about/rappler-privacy-statement">
                Privacy Statement
              </Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/about/rappler-community-and-site-use-rules">
                Terms of Use
              </Link>
            </li>
            <li>
              <Link href="https://www.rappler.com/about/comment-moderation-policy">
                Comment Moderation Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
