import styles from "../styles/ProgressBar.module.scss";

const ProgressBarDefault = ({ percentage }) => {
  return (
    <div className={styles.progressContainer}>
      <progress value={percentage} max="100" />
    </div>
  );
};

const ProgressBarWidget = ({ percentage, color }) => {
  return (
    <div className={`${styles.progressContainerResults} ${color}`}>
      <progress value={percentage} max="100" />
    </div>
  );
};

export { ProgressBarDefault, ProgressBarWidget };
